

















































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'
@Observer
@Component({})
export default class StakingDialog extends Vue {
  @Inject() vm!: StakingViewModel
  getBSL() {
    window.open(`https://pancakeswap.finance/swap?outputCurrency=${this.vm.stakingHandler!.poolToken}`, '_blank')
  }
}
